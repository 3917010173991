<div *ngIf="showBrand" class="banner">
  <div class="banner-content">
  <!--img class="society" src="../../../assets/img/society.svg" alt=""-->
  <!--img class="coffee" src="../../../assets/img/coffee.png" alt=""-->

  <h3>{{ service?.title || '' }}</h3>
  <hr>
  <p> {{ service?.description || ''}} </p>
  </div>
  <div></div>
</div>
<!-- <section class="top inset">
  <div [style.background-image]="getImage()"  class="landing-image" *ngIf="showBrand">
    <div>
      <img src="../../../assets/images/unido-brand.svg">
    </div>
  </div>
  <div class="welcome-text text-center">
    <img src="../../../assets/images/coffee-plan-2.svg" *ngIf="showProduct">
    <ng-container *ngIf="showTitle">
      <h1></h1>
      <div class="dash y-center"></div>
    </ng-container>
    <p class="text-font-regular">

    </p>
  </div>
</section> -->
