import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.scss']
})
export class PlanComponent {
  @Input() class = 'plan';
  @Input() description = '';
  @Input() image = '';
  @Input() title = '';
}
