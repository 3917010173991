<ngx-spinner></ngx-spinner>
<section class="middle inset">
    <div class="payment-form text-center y-center">

        <div class="steps container-center text-dark">

            <div class="step">
              <span>Inicia sesión en tu cuenta</span>
            </div>
          </div>


          <form [formGroup]="signinForm">
            <div class="form">
                <div *ngIf="email" class="row">
                    <div class="form-control">
                      <input [formControl]="signinForm.controls['username']" type="text" placeholder="Introduce tu email"/>
                      <br>
                      <small
                      *ngIf="signinForm.controls['username'].hasError('required') && signinForm.controls['username'].touched" 
                      class="form-error-msg"> Usuario es requerido </small>
                      <small 
                      *ngIf="signinForm.controls['username'].hasError('email') && signinForm.controls['username'].touched" 
                      class="form-error-msg"> Email Invalido. El formato debe ser example@dot.com </small>
                    </div>
                  </div>
                <div *ngIf="password" class="row">
                  
                  <div class="form-control">
                    
                    <input [formControl]="signinForm.controls['password']" type="password" placeholder="Contraseña"/>
                    <small 
                    *ngIf="signinForm.controls['password'].hasError('required') && signinForm.controls['password'].touched" 
                    class="form-error-msg"> Contraseña es requerida </small>
                  </div>
                </div>
          
              </div>

              <small 
              *ngIf="error" 
              class="form-error-msg"> ERROR !!! {{ error }} </small>

              <button style="margin-right: 20px; width: 14%;" *ngIf="password" type="button" (click)="goBack()" >Volver</button>

              <button *ngIf="password" type="button" (click)="signin()" class="btn yellow" [disabled]="signinForm.invalid" >Acceder</button>
             
              <button *ngIf="email" type="button" (click)="findEmail()" class="btn yellow" [disabled]="signinForm.controls['username'].hasError('required')" >Continuar</button>
              
          </form>
  
      
      <div>
        
        <br/>
        <button *ngIf="email" class="btn dark btn mini underlined" [routerLink]="['../register']">Crear cuenta</button>
        <button *ngIf="password" class="btn dark btn mini underlined" (click)="recoverPass()">Olvide mi contraseña </button>
      </div>
    </div>
  
    
    <div class="ending"></div>
  </section>