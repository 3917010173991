import {NgModule} from '@angular/core';
import {FooterComponent} from './footer.component';
import {RouterModule} from '@angular/router';
import {TranslocoModule} from '@ngneat/transloco';

@NgModule({
  declarations: [
    FooterComponent
  ],
  providers: [],
  exports: [
    FooterComponent
  ],
  imports: [
    RouterModule,
    TranslocoModule
  ],
  bootstrap: [FooterComponent]
})
export class FooterModule {
}
