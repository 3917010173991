import {NgModule} from '@angular/core';
import {PlanComponent} from './plan.component';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';

@NgModule({
  declarations: [
    PlanComponent
  ],
  providers: [],
  exports: [
    PlanComponent
  ],
  imports: [
    RouterModule,
    CommonModule
  ],
  bootstrap: [PlanComponent]
})
export class PlanModule {
}
