import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import {ServiceService} from '../../services/service.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { TableService } from '../../services/pager';
import { NgxSpinnerService } from "ngx-spinner";
import { PageEvent } from '@angular/material/paginator';
import { BaseService } from '../../services/base.service';
import { findPhoneNumbersInText } from 'libphonenumber-js'

@Component({
  selector: 'app-addreses',
  templateUrl: './addreses.component.html',
  styleUrls: ['./addreses.component.scss']
})
export class AddresesComponent implements OnInit {
  form: any;
  formEdit:any;
  error = '';
  register = false;
  edit = false;
  tarjetas : any = [];
  items: any = [];
  customer: any;
  confirm: boolean = false;
  errorUpdate = '';
  provinces: any[]= [] ;
  corregimientos: any[]= [] ;
  errorNumber = true;
  numberRequired = false;
  phone : any;
  configuration  = {initialCountry: "PA",separateDialCode:true} ;

  // PARA MANEJO DE TABLAS
  //@ViewChild(MatPaginator) paginator: MatPaginator ;
  //@ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = [ 'adress' ,'phone', 'status','actions'];
  dataSource: any;

  constructor(private baseService : BaseService,private spinner: NgxSpinnerService,private call : ServiceService,public  tableService: TableService<any>,private router: Router,
		private activatedRoute: ActivatedRoute) { }


  ngOnInit(): void {
    this.spinner.show();
    this.tableService.pager.pageSize = 10 ; 
    this.form = new FormGroup({
      lastname: new FormControl('', Validators.required),
      name: new FormControl('', Validators.required),
      phone: new FormControl('', Validators.required),
      adress: new FormControl('', Validators.required),
      province : new FormControl('', Validators.required),
      corregimiento : new FormControl('', Validators.required)
    });

      let data : any = localStorage.getItem('currentData');
      this.customer = JSON.parse(data).customer
      this.list();

  }

  list(event?: PageEvent){

    let httpParams = this.baseService.buildRequestParams(this.tableService.sort,"m",
    {pageIndex: event ? event.pageIndex : this.tableService.pager.pageIndex , pageSize: event ? event.pageSize : this.tableService.pager.pageSize});

    this.call.getAddresses(this.customer, httpParams).subscribe(response =>{
      
      for(let adress of response.result){
        adress.status = adress.status == 1 ? "Activo" : "Inactivo";
      }
     

      this.call.getService(Number(localStorage.getItem("service"))).subscribe(service =>{
      // this.call.GetProvinces().subscribe(provinces =>{
       this.call.get(this.call.HOST + "/partner/" + service.partner_id).subscribe(partner =>{
       
       this.provinces = partner.result.provinces;
        this.provinces = this.provinces.sort((a,b) => a.name > b.name ? 1 : -1);// ORDENAMOS ALFABETICAMENTE
        this.spinner.hide()
        this.dataSource = new MatTableDataSource<any>(response.result);
        this.tableService.pager = response.pager;
        this.tableService.selection.clear();

      });

    });
      
    });

  }

  send(){
    this.clearMessages();
    this.spinner.show()
    const data = this.form.value;


      data.customer = { id: this.customer };
      data.status = 1;
      data.province = {id: data.province.id };

      this.call.createAdress(data).subscribe(response =>{
        this.register = false;
        this.ngOnInit();
      }, err =>{
        console.log(err);
        this.error = err;
      }) 


   
    
  }


  //Permite solo numeros en el numero de la TDC
  onlyNumberKey(event : any){
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  goBack(){
  this.register = false;
  }

  goBackEdit(){
    this.edit = false;
  }

  add(){
    this.register = true;
  }

  update(row:any){
    this.clearMessages();
    this.spinner.show()
    this.formEdit = new FormGroup({
      id: new FormControl(row.id, Validators.required),
      lastname: new FormControl(row.lastname, Validators.required),
      name: new FormControl(row.name, Validators.required),
      phone: new FormControl(),
      adress: new FormControl(row.adress, Validators.required),
      province : new FormControl(row.province.id, Validators.required),
      corregimiento : new FormControl(row.corregimiento.id, Validators.required)
    });

    row.phone

    if(row.phone != undefined || row.phone != null){

      let findPhone = findPhoneNumbersInText(row.phone);
      if(findPhone.length > 0){
        this.phone = findPhone[0].number.nationalNumber;
        this.configuration  = {initialCountry: findPhone[0].number.country! ,separateDialCode:true} ;
      }
      
    }

    this.onProvinceEditChange(row.province.id);


    //console.log(row.province);
    //this.formEdit.controls["province"].setValue(row.province.id);
    this.edit = true;
    this.spinner.hide()
  }

  delete(row:any){
    this.spinner.show()
    this.clearMessages();
    this.call.deleteAdress(row.id).subscribe(response =>{
      this.ngOnInit();
    }, err =>{
      console.log(err);
      this.spinner.hide()
      this.errorUpdate = err;
    })
  }

  clearMessages(){
    if(this.errorUpdate){
      this.errorUpdate = '';
    }
    if(this.error){
      this.error = '';
    }
  }

  sendEdit(){
    this.clearMessages();
    this.spinner.show();
    const data = this.formEdit.value;
    data.customer = { id: this.customer };
    
    data.province = {id: data.province };
    data.corregimiento = {id: data.corregimiento };

    this.call.updateAdress(data).subscribe(response =>{
      
      this.edit = false;
      this.ngOnInit();
    }, err =>{
      console.log(err);
      this.spinner.hide()
      this.error = err;
    }) 
  }

  onProvinceChange(province:any){
    this.corregimientos = [];
    this.corregimientos = province.corregimientos;
  }

  onProvinceEditChange(province:any){
    this.corregimientos = [];
    // this.formEdit.controls["corregimiento"].setValue(undefined);
    console.log('this.province->',this.provinces.findIndex(t => t.id == province));
    this.corregimientos = this.provinces[ this.provinces.findIndex(t => t.id == province)==-1?0:
      this.provinces.findIndex(t => t.id == province)].corregimientos;
    this.corregimientos.sort((a,b) =>  (a.name > b.name ? 1 : -1));
  }

  hasError(obj:any){
    // FALSE : ERROR - TRUE -> NO ERROR
    this.errorNumber = obj;
    if(obj){
      this.numberRequired = true;
    }
  }

  onCountryChange(obj:any){
    //console.log(obj);
  }

  telInputObject(obj:any){
    //console.log(obj);
  }

  getNumber(obj:any){
    this.form.controls["phone"].setValue(obj);
  }

  getEditNumber(obj:any){
    this.formEdit.controls["phone"].setValue(obj);
  }


}
