import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { JwtAuthService } from '../../services/auth/jwt-auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import {ServiceService} from '../../services/service.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-verify-token',
  templateUrl: './verify-token.component.html',
  styleUrls: ['./verify-token.component.scss']
})
export class VerifyTokenComponent implements OnInit {

  form: any;
  error : any;
  resend = false;
  success : any;


  constructor(private spinner: NgxSpinnerService,private call : ServiceService,private jwtAuth: JwtAuthService,private router: Router,private activateRoute: ActivatedRoute) {
    
   }

   ngOnInit(): void {

    this.call.getConfig(window.location.host).subscribe(response =>{

      localStorage.setItem("service",response.value_config);

  });

    this.spinner.show();
 
    this.form = new FormGroup({
        token: new FormControl('', Validators.required)
    });



    this.spinner.hide();
    
  }

  verifyToken(){
    this.spinner.show();
    this.success = false;
    const data = this.form.value;
    data.service = localStorage.getItem("service");

    this.call.verifyToken(data).subscribe(response=>{
      this.router.navigateByUrl("/updatePayment/" + response + "/" + data.token);
    this.spinner.hide();
    }, err =>{

      if(err.status != 404){
        this.resend = true;
      }else{
        this.resend = false;
      }
      this.error = err.error.message ;
      this.spinner.hide();
      
    })

    
  }

  newToken(){
    const data = this.form.value;
    data.service = localStorage.getItem("service");
    this.call.resendToken(data).subscribe(response =>{
      
      this.error = false;
      this.success = "Hemos enviado un nuevo token, por favor ingreselo";
      this.resend = false;
      this.form.reset();

    }, err =>{
      console.log(err);
    })
  }

  onlyNumberKey(event : any){
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

}
