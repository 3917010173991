import { Injectable } from '@angular/core';
declare let gtag:Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor() {
  }

  public eventEmitterCheckout(eventName: string, eventCategory: string, eventAction: string, eventLabel: string ,  eventValue: number,option : any, customer : any)
  { 
    gtag('event', eventName, { 
                 eventCategory: eventCategory, 
                 eventLabel: eventLabel, 
                 eventAction: eventAction, 
                 eventValue: eventValue,
                 eventCustomer : customer,
                 eventOption : option
    })
  }

  public eventEmitterSelection(eventName: string, eventCategory: string, eventAction: string, eventLabel: string ,  eventValue: number, option : any, customer : any)
  { 
    gtag('event', eventName, { 
                 eventCategory: eventCategory, 
                 eventLabel: eventLabel, 
                 eventAction: eventAction, 
                 eventValue: eventValue,
                 eventCustomer : customer,
                 eventOption : option
    })
  }

}
