import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import {ServiceService} from '../../services/service.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { TableService } from '../../services/pager';

import {AddressesPopupComponent} from '../checkout/addresses-popup/addresses-popup.component'
import {ValidateComponent} from './validate/validate.component'


import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { TdcPopupComponent } from '../checkout/tdc-popup/tdc-popup.component';
import { NgxSpinnerService } from "ngx-spinner";
import { PageEvent } from '@angular/material/paginator';
import { BaseService } from '../../services/base.service';


@Component({
  selector: 'app-suscriptions',
  templateUrl: './suscriptions.component.html',
  styleUrls: ['./suscriptions.component.scss']
})
export class SuscriptionsComponent implements OnInit {

  form: any;
  formEdit:any;
  error = '';
  register = false;
  edit = false;
  tarjetas : any = [];
  items: any = [];
  customer: any;
  confirm: boolean = false;
  adress : any = null;
  info = '';
  tdc: any = null;
  provinces: any[]= [] ;
  corregimientos: any[]= [] ;
  activa = false;
  congelada = false;


  // PARA MANEJO DE TABLAS
  //@ViewChild(MatPaginator) paginator: MatPaginator ;
  //@ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['plan.title', 'option.name' ,'plan.feeCost_plan', 'plan.frecuency.name_frecuency' ,'next_bill_date','status_customerPlan', 'actions'];
  dataSource: any;

  constructor(private baseService : BaseService,private spinner: NgxSpinnerService,private dialog: MatDialog,private call : ServiceService,public  tableService: TableService<any>,private router: Router,
		private activatedRoute: ActivatedRoute) { }


  ngOnInit(): void {
    this.activa = false;
    this.congelada = false;

    this.tableService.pager.pageSize = 10 ; 

    this.spinner.show();

    this.call.getUpdate2().subscribe(message => {
      this.edit = JSON.parse(message.text);
    });

    this.form = new FormGroup({
      lastname: new FormControl('', Validators.required),
      name: new FormControl('', Validators.required),
      phone: new FormControl(''),
      adress: new FormControl('', Validators.required)
    });

      let data : any = localStorage.getItem('currentData');
      

      this.customer = JSON.parse(data).customer;

      this.list();
      

  }

  list(event?: PageEvent){

    let httpParams = this.baseService.buildRequestParams(this.tableService.sort,"m",
    {pageIndex: event ? event.pageIndex : this.tableService.pager.pageIndex , pageSize: event ? event.pageSize : this.tableService.pager.pageSize});
    this.call.getHasPlans(this.customer, httpParams).subscribe(response =>{
     
      for(let sus of response.result){
        switch (sus.status_customerPlan){
          case 1 : sus.status_customerPlan = "Activa";
                 this.activa = true;
                  break;
          case 2 : sus.status_customerPlan = "Inactiva";
                  break;
          case 3 : sus.status_customerPlan = "Congelada";
                 this.congelada = true;
                  break;
          case 3 : sus.status_customerPlan = "Suscripción no procesada";
                  break;
          case 5 : sus.status_customerPlan = "No se pudo cobrar";
                  break;
        }
      }

      this.call.GetProvinces().subscribe(provinces =>{
        this.provinces = provinces;

        this.spinner.hide();
        this.dataSource = new MatTableDataSource<any>(response.result);
        this.tableService.pager = response.pager;
        this.tableService.selection.clear();
       
      });

      
    })
  }

  send(){
    this.clearMessages()
    this.spinner.show();
    const data = this.form.value;
      data.customer = { id: this.customer };
      data.status = 1;
      this.call.createAdress(data).subscribe(response =>{
        this.register = false;
        this.ngOnInit();
      }, err =>{
        console.log(err);
        this.error = err;
      }) 
  }

  clearMessages(){
    if(this.info){
      this.info = '';
    }
    if(this.error){
      this.error = '';
    }
  }


  //Permite solo numeros en el numero de la TDC
  onlyNumberKey(event : any){
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  goBack(){
  this.register = false;
  }

  goBackEdit(){
    this.edit = false;
  }

  add(){
    this.register = true;
  }

  pause(){
    this.spinner.show();
    this.call.pause(this.formEdit.value.id).subscribe(response =>{
        this.edit = false;
        this.ngOnInit();
    }, (err) =>{
      this.error = err;
    })
  }

  cancel(){
    this.spinner.show();
    this.call.cancel(this.formEdit.value.id).subscribe(response =>{
      this.edit = false;
      this.ngOnInit();
    }, (err) =>{
      this.error = err;
    })
  }

  activate(){
    this.spinner.show();
    this.call.activate(this.formEdit.value.id).subscribe(response =>{
      this.edit = false;
      this.ngOnInit();
    }, (err) =>{
      this.error = err;
    })
  }

  update(row:any){
 
    this.clearMessages();
    this.spinner.show();
    this.formEdit = new FormGroup({
      id: new FormControl(row.id, Validators.required),
      product: new FormControl(row.plan.title, Validators.required),
      price: new FormControl('$'+row.plan.feeCost_plan, Validators.required),
      frecuency: new FormControl(row.plan.frecuency.name_frecuency),
      next: new FormControl(row.next_bill_date, Validators.required),
      adress: new FormControl('', Validators.required),
      name_tdc: new FormControl('', Validators.required),
      number_tdc: new FormControl('', Validators.required),
      month: new FormControl('', Validators.required),
      year: new FormControl('', Validators.required),
      province : new FormControl('', Validators.required),
      corregimiento : new FormControl('', Validators.required)
    });

    switch(row.status_customerPlan){
      case "Activa": this.activa = true;
      break;
      case "Congelada":this.congelada = true;
      break;
      default: this.activa=false; this.congelada=false;
      break;
    }

   
    this.call.getAdress(row.adress_id).subscribe(response =>{
      
      this.formEdit.controls["adress"].setValue(response.adress);
      if(response.province != null){
        this.formEdit.controls["province"].setValue(response.province.name);
        this.formEdit.controls["corregimiento"].setValue(response.corregimiento.name);
      }
     

      this.edit = true;
      this.call.findByCustomerInCorer(this.customer).subscribe(response=>{

        this.tarjetas = response.result;
        this.spinner.hide();
        let t = this.tarjetas[this.tarjetas.findIndex((t:{ tokenCreditCard: any; }) => t.tokenCreditCard == row.creditCardToken.token_creditCardToken)];
        this.formEdit.controls["number_tdc"].setValue(t.creditCard.maskCreditCard);
        this.formEdit.controls["name_tdc"].setValue(t.creditCard.nameHolderCreditCard);

        // para el mes 
        let month;
        switch(t.creditCard.yearMonthExpiration.substr(t.creditCard.yearMonthExpiration.length - 2)){
          case "01" : month = "ENERO";
          break;
          case "02" : month = "FEBRERO";
          break;
          case "03" : month = "MARZO";  
          break;
          case "04" : month = "ABRIL";  
          break;
          case "05" : month = "MAYO";  
          break;
          case "06" : month = "JUNIO";  
          break;
          case "07" : month = "JULIO";  
          break;
          case "08" : month = "AGOSTO";  
          break;
          case "09" : month = "SEPTIEMBRE";  
          break;
          case "10" : month = "OCTUBRE";  
          break;
          case "11" : month = "NOVIEMBRE";  
          break;
          case "12" : month = "DICIEMBRE";  
          break;
        }


        this.formEdit.controls["year"].setValue("20" + t.creditCard.expYearCreditCard);
        this.formEdit.controls["month"].setValue(month);
      })

    });
    
  }

  delete(row:any){
    this.spinner.show();
    this.call.deleteAdress(row.id).subscribe(response =>{
      this.ngOnInit();
    }, err =>{
      console.log(err);
      this.error = err;
    })
  }

  sendEdit(){
    this.clearMessages();
    this.spinner.show();
    if(this.adress != null || this.tdc != null){
      const data: any = {} //this.formEdit.value;
      data.customer = this.customer
      if(this.adress != null){
        data.adress = {id:this.adress} ;
      }
      
      if( this.tdc != null){
        data.tdc = {id:this.tdc}
      }
      
     data.hasPlan = this.formEdit.value.id;

     this.call.updateShippingAdress(data).subscribe(response =>{
        
        this.edit = false;
        this.ngOnInit();
  
      }, err =>{
        console.log(err);
        this.error = err;
      })
    }else{
      this.info = "Debe seleccionar una direccion de envío o una tarjeta distinta a la actual";
        this.spinner.hide();
    }
    
  }


  addressesPopUp(data: any = {}) {
    
    let dialogRef: MatDialogRef<any> = this.dialog.open(AddressesPopupComponent, {
      width: '100%',
      height:'50%',
      disableClose: true,
      data: { customer: this.customer }
    })
    dialogRef.afterClosed()
      .subscribe(res => {
        if(!res) {
          // If user press cancel
          return;
        }
        // TOMAMOS LA INFORMACION DE LA DIRECCION SELECCIONADA
        this.adress = res.id;
        this.formEdit.controls["adress"].setValue(res.adress);
       
        this.formEdit.controls["province"].setValue(res.province.name);
        this.formEdit.controls["corregimiento"].setValue(res.corregimiento.name);
       
      })
  }

  tdcPopUp(data: any = {}) {
    
    let dialogRef: MatDialogRef<any> = this.dialog.open(TdcPopupComponent, {
      width: '100%',
      height:'50%',
      disableClose: true,
      data: { customer: this.customer }
    })
    dialogRef.afterClosed()
      .subscribe(res => {
        if(!res) {
          // If user press cancel
          return;
        }
       // console.log(res.token);
      
        this.tdc = res.token;
        this.formEdit.controls["name_tdc"].setValue(res.nameHolderCreditCard);
        this.formEdit.controls["number_tdc"].setValue(res.maskCreditCard);
         // para el mes 
         let month;
         switch(res.yearMonthExpiration.substr(res.yearMonthExpiration.length - 2)){
           case "01" : month = "ENERO";
           break;
           case "02" : month = "FEBRERO";
           break;
           case "03" : month = "MARZO";  
           break;
           case "04" : month = "ABRIL";  
           break;
           case "05" : month = "MAYO";  
           break;
           case "06" : month = "JUNIO";  
           break;
           case "07" : month = "JULIO";  
           break;
           case "08" : month = "AGOSTO";  
           break;
           case "09" : month = "SEPTIEMBRE";  
           break;
           case "10" : month = "OCTUBRE";  
           break;
           case "11" : month = "NOVIEMBRE";  
           break;
           case "12" : month = "DICIEMBRE";  
           break;
         }
 
 
         this.formEdit.controls["year"].setValue(res.expYearCreditCard);
         this.formEdit.controls["month"].setValue(month);
       
      })
  }

  validatePausePopUp(data: any = {}) {
    
    let dialogRef: MatDialogRef<any> = this.dialog.open(ValidateComponent, {
      width: '50%',
      height:'30%',
      disableClose: true,
      data: { customer: this.customer }
    })
    dialogRef.afterClosed()
      .subscribe(res => {
        if(!res) {
          // If user press cancel
          return;
        }
        // TOMAMOS LA INFORMACION DE LA DIRECCION SELECCIONADA
        if(res){
          this.pause();
        }
       
      })
  }

  validateCancelPopUp(data: any = {}) {
    
    let dialogRef: MatDialogRef<any> = this.dialog.open(ValidateComponent, {
      width: '50%',
      height:'30%',
      disableClose: true,
      data: { customer: this.customer }
    })
    dialogRef.afterClosed()
      .subscribe(res => {
        if(!res) {
          // If user press cancel
          return;
        }
        // TOMAMOS LA INFORMACION DE LA DIRECCION SELECCIONADA
       
       if(res){
         this.cancel();
       }
       
      })
  }

  validateActivatePopUp(data: any = {}) {
    
    let dialogRef: MatDialogRef<any> = this.dialog.open(ValidateComponent, {
      width: '50%',
      height:'30%',
      disableClose: true,
      data: { customer: this.customer }
    })
    dialogRef.afterClosed()
      .subscribe(res => {
        if(!res) {
          // If user press cancel
          return;
        }
        // TOMAMOS LA INFORMACION DE LA DIRECCION SELECCIONADA
        if(res){
          this.activate();
        }
       
      })
  }

  onBackButtonClicked() {
    this.router.navigate(['/suscriptions']);
  }


}
