import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import {ServiceService} from '../../../services/service.service';
import { TableService } from '../../../services/pager';
import { PageEvent } from '@angular/material/paginator';
import { BaseService } from '../../../services/base.service';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-tdc-popup',
  templateUrl: './tdc-popup.component.html',
  styleUrls: ['./tdc-popup.component.scss']
})
export class TdcPopupComponent implements OnInit {

  show = true;

  tdcSelected: any;
  tarjetas : any = [];
  items: any = [];
  empty = false;


  displayedColumns: string[] = ['nameHolderCreditCard' ,'expMonthCreditCard','actions'];
  dataSource: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TdcPopupComponent>,
    private call : ServiceService,public  tableService: TableService<any>,private baseService : BaseService
  ) { }


  ngOnInit(): void {
    this.tableService.pager.pageSize = 10 ; 
    this.call.findByCustomerInCorer(this.data.customer).subscribe(params =>{

      if(params['result'] != null){
        this.tarjetas = params['result'];
        //Por cada tarjeta
        for(let i in this.tarjetas){
          let tdc = this.tarjetas[i].creditCard;
          //Se verifica la integridad de la tdc chequeando si posee un token en HecBillingCustomer
          this.call.findByToken(this.data.customer,this.tarjetas[i].tokenCreditCard).subscribe(params => {
          
            //Si la tarjeta esta correctamente referenciada
            if(params['result'] != null) {
              tdc.expMonth = tdc.expMonthCreditCard;
              //Se carga el nombre del proveedor TDC en la columna indicada
              tdc.expMonthCreditCard = this.tarjetas[i].providerCreditCard.description;
              //Se corta el numero enmascarado de la TDC de manera de mostrar solo los ultimos 8 digitos
              tdc.maskCreditCard = tdc.maskCreditCard.substr(tdc.maskCreditCard.length-8);
              //Se muestra el año de vencimiento de la tarjeta en el formato YYYY
              tdc.expYearCreditCard = "20" + tdc.expYearCreditCard;
              //Se busca el estatus de la TDC de manera de mostrarlo al cliente
              this.call.findTdcById(this.data.customer,tdc.id).subscribe(parms => {
                //Se guarda la descripcion del estado en la columna correspondiente
                
                tdc.estatus = parms['result']['statusCreditCard'] == 1 ? "Activo" : "Inactivo";
                tdc.token = parms['result']['tokenCreditCard'];
                this.show = false;
                //Se anade la TDC a la tabla correspondiente

                if(parms['result']['statusCreditCard'] == 1){ // SOLO SI LA TARJETA ESTA ACTIVA
                  this.items.push(tdc);
                  this.dataSource = new MatTableDataSource<any>(this.items.slice(0,11));
                  this.tableService.pager.totalEntities = this.items.length ;
                }
                
              }, (err) => console.log(err) );
            }
           

          }, (err) => {
            
            console.log("ERRORRR");
            console.log(err)
           } );
        }
        
      }else{
        this.empty = true;
        this.show = false;
        console.log("NO TDC")
      }
    })



  }

  list(event?: PageEvent){

    let size = event ? event.pageSize : this.tableService.pager.pageSize
    let index = event ? event.pageIndex : this.tableService.pager.pageIndex;
    let itemsToShow;
    if(index == 0){
      itemsToShow = this.items.slice(0, size + 1)
    }else{
      itemsToShow = this.items.slice( (size*index), (size * (index * 2)) + 1)
    }

    if(itemsToShow.length == 0){
      this.empty = true;
    }
    
    this.tableService.pager.totalEntities = this.items.length -1;
    this.dataSource = new MatTableDataSource<any>(itemsToShow);

  }

  select(row : any) {
    this.dialogRef.close(row);
  }

}
