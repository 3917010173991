<ngx-spinner></ngx-spinner>
<section class="middle inset">
    <div class="payment-form text-center y-center">

        <div class="steps container-center text-dark">
           
            <div class="step">
              <span>Por favor introduce tu email y te enviaremos tu password</span>
            </div>
          </div>


          <form [formGroup]="signinForm" (ngSubmit)="recover()">
            <div class="form">
                <div  class="row">

                    <div class="form-control">
                      <input [formControl]="signinForm.controls['username']" type="text" placeholder="Email"/>
                      <br>
                      <small
                      *ngIf="signinForm.controls['username'].hasError('required') && signinForm.controls['username'].touched" 
                      class="form-error-msg"> Email es requerido </small>
                      <small 
                      *ngIf="signinForm.controls['username'].hasError('email') && signinForm.controls['username'].touched" 
                      class="form-error-msg"> Email Invalido. El formato debe ser example@dot.com </small>
                    </div>
                </div>
                
              </div>

              <small 
              *ngIf="error" 
              class="form-error-msg"> ERROR !!! {{ error }} </small>

              <button style="margin-right: 20px; width: 14%;"  type="button" (click)="goBack()" >Volver</button>
            
              <button class="btn yellow" [disabled]="signinForm.invalid" >Continuar</button>
             
            
          </form>
  
      
      <div>
    
      </div>
    </div>
  
    
    <div class="ending"></div>
  </section>
