import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {

  title = 'united-coffee-landing';
  plans = [
    {
      description: 'plan-one-desc',
      image: '../../../assets/images/plan.svg',
      title: 'plan-one-name'
    },
    {
      description: 'plan-two-desc',
      image: '../../../assets/images/plan-2.svg',
      title: 'plan-two-name'
    }
  ];
  plan: any = {};

  constructor() {
  }

  ngOnInit(): void {
    this.plan = this.plans[0];
  }
}
