<ngx-spinner></ngx-spinner>
<section class="middle inset">
    <div class="payment-form text-center y-center">


        <div class="steps container-center text-dark">
           
            <div class="step">
              <span>Crea tu cuenta</span>
            </div>
          </div>


          <form [formGroup]="signupForm" (ngSubmit)="signup()">
            <div class="form">

                <div class="row">
                    <div class="form-control">
                      <input [formControl]="signupForm.controls['name_customer']" type="text" placeholder="Nombre"/>
                      <small
                      *ngIf="signupForm.controls['name_customer'].hasError('required') && signupForm.controls['name_customer'].touched" 
                      class="form-error-msg"> Nombre es requerido </small>
                    </div>
                    <div class="form-control">
                      <input [formControl]="signupForm.controls['lastName_customer']" type="text" placeholder="Apellido"/>
                      <small
                      *ngIf="signupForm.controls['lastName_customer'].hasError('required') && signupForm.controls['lastName_customer'].touched" 
                      class="form-error-msg"> Apellido es requerido </small>
                    </div>
                </div>

                <div class="row">
                    <div class="form-control">
                      <input [formControl]="signupForm.controls['identifier_customer']" type="text" placeholder="Cédula"/>
                      <small 
                      *ngIf="signupForm.controls['identifier_customer'].hasError('required') && signupForm.controls['identifier_customer'].touched" 
                      class="form-error-msg"> Cédula es requerido </small>
                    </div>
                    <div class="form-control">
                      <input [formControl]="signupForm.controls['email_customer']" type="text" placeholder="Email"/>
                      <small 
                      *ngIf="signupForm.controls['email_customer'].hasError('required') && signupForm.controls['email_customer'].touched" 
                      class="form-error-msg"> Email es requerido </small>

                      <small 
                      *ngIf="signupForm.controls['email_customer'].hasError('email') && signupForm.controls['email_customer'].touched" 
                      class="form-error-msg"> Email Invalido. El formato debe ser example@dot.com </small>
                    </div>

                    <div class="form-control">
                
                      <input style="width: 70%; margin-left: 85px; padding-left: 0px;"  (hasError)="hasError($event)"  (countryChange)="onCountryChange($event)" (intlTelInputObject)="telInputObject($event)" (ng2TelOutput)="getNumber($event)" [ng2TelInputOptions]="{initialCountry: 'pa',separateDialCode:true}" type="text" ng2TelInput />
   
   
                      <small
                      *ngIf="!errorNumber" 
                      class="form-error-msg"> Número de teléfono inválido </small>
   
                     </div>
                    
                  

                </div>
          
              </div>

              <small 
              *ngIf="error" 
              class="form-error-msg"> ERROR !!! {{ error }} , disculpe por favor verifique datos</small>

              <button class="btn" [disabled]="signupForm.invalid || !numberRequired" >Registrarse</button>
          
          </form>
  
      
      <div>
      </div>
    </div>
  
    
    <div class="ending"></div>
  </section>
