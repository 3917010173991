<ngx-spinner></ngx-spinner>
<section class="middle inset">
    <div class="payment-form text-center y-center">


        <div class="steps container-center text-dark">
           
            <div class="step">
              <span>Cambiar mi contraseña</span>
            </div>
          </div>


          <form [formGroup]="form" (ngSubmit)="change()">
            <div class="form">
                <div  class="row">

                    <div class="form-control">
                      <input [formControl]="form.controls['actual_password']" type="password" placeholder="Contraseña actual"/>
                      <br>
                      <small
                      *ngIf="form.controls['actual_password'].hasError('required') && form.controls['actual_password'].touched" 
                      class="form-error-msg"> Contraseña es requerido </small>
                     
                    </div>
                    <div class="form-control">
                        <input [formControl]="form.controls['new_password']" type="password" placeholder="Contraseña nueva"/>
                        <br>
                        <small
                        *ngIf="form.controls['new_password'].hasError('required') && form.controls['new_password'].touched" 
                        class="form-error-msg"> Nueva contraseña es requerido </small>

                        <small
                        *ngIf="form.controls['new_password'].hasError('minlength') && form.controls['new_password'].touched" 
                        class="form-error-msg"> Nueva contraseña debe tener al menos 6 digitos </small>
                       
                    </div>
                      <div class="form-control">
                        <input [formControl]="form.controls['newPasswordConfirm']" type="password" placeholder="Confirma la nueva contraseña"/>
                        <br>
                        <small
                        *ngIf="form.controls['newPasswordConfirm'].hasError('required') && form.controls['newPasswordConfirm'].touched" 
                        class="form-error-msg"> Contraseña es requerido </small>
                        <small *ngIf="form.controls['newPasswordConfirm'].hasError('equalTo')" class="form-error-msg">Contraseñas no coinciden</small>
                       
                      </div>
                </div>
                
              </div>

              <small 
              *ngIf="error" 
              class="form-error-msg"> ERROR !!! {{ error }} </small>

              <small 
              *ngIf="success" 
              class="form-success-msg"> Su ha sido cambiada exitosamente !!!</small>

              
              <button class="btn yellow" [disabled]="form.invalid" >Cambiar</button>
             
            
          </form>
  
      
      <div>
    
      </div>
    </div>
  
    
    <div class="ending"></div>
  </section>
