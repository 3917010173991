import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { JwtAuthService } from '../../services/auth/jwt-auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import {ServiceService} from '../../services/service.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastService } from 'src/app/services/notification/toast.service';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.scss']
})
export class RecoverPasswordComponent implements OnInit {

  signinForm: any;
  error : any;

  email : any = true;
  partner : any;

 
  constructor( private toast : ToastService,private spinner: NgxSpinnerService,private call : ServiceService,private jwtAuth: JwtAuthService,private router: Router,private activateRoute: ActivatedRoute) {
    
   }

  ngOnInit(): void {
    this.spinner.show();
    this.signinForm = new FormGroup({
      username: new FormControl('', [Validators.required,Validators.email])
    });

    this.call.getService(Number(localStorage.getItem("service"))).subscribe(response =>{
      this.spinner.hide();
      this.partner = response.partner_id;
  }, err =>{
    this.spinner.hide();
    console.log(err);
  })

    this.spinner.hide();
  }

  findEmail(){
    this.spinner.show();
    // BUSCAMOS EL EMAIL
    this.call.getAuth(this.signinForm.value.username, this.partner).subscribe(response =>{
      // AQUI LO ENCONTRO ENTONCES MOSTRAMOS PARA INTRODUCIR EL PASSWORD
     
      this.email = false;
      this.spinner.hide();

    }, err =>{
      this.spinner.hide();
      this.toast.showInfo("Correo no registrado, por favor crea tu cuenta...")
      // SI ENTRA POR AQUI ES QUE NO LO ENCONTRO REGISTRADO POR LO QUE MOSTRAMOS PANTALLA DE REGISTRO
      this.activateRoute.params.subscribe(params=>{
        this.router.navigateByUrl("/" + params.service + "/register");
      })
    })
    
  }

  goBack(){
  
     this.activateRoute.params.subscribe(params=>{
      this.router.navigate(["/" + params.service + "/login"], {relativeTo: this.activateRoute, queryParamsHandling: 'preserve'});
    })
  }

  recover() {
    this.spinner.show();
    const signinData = this.signinForm.value;


    this.activateRoute.params.subscribe( params =>{
    this.call.recoverPassword(signinData.username,Number(params["service"]))
    .subscribe(response => {
      this.spinner.hide();
      this.activateRoute.params.subscribe(params=>{
        
        this.toast.showSuccess("Te enviaremos un correo con tu nueva clave, por favor inicia sesion con tus nuevas credenciales")
        this.router.navigate(["/" + params.service + "/login"],{ queryParams: { recover: signinData.username}});
      })
     
    }, err => {
      this.spinner.hide();
      console.log(err);
      this.error = 'Email no registrado, por favor intente nuevamente';
  
    }) 

    })
    
  }


}
