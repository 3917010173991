<section class="bottom">
  <div class="container">
    <div class="col text-left">
      <h3 style="color: white;" >Principal</h3>
      <div class="dash"></div>
      <ul>
        <li>
          <a href="https://cafeunido.com/sp/about-us/" target="_blank">Sobre nosotros</a>
        </li>
        <li>
          <a href="https://cafeunido.com/sp/panama-coffee/" target="_blank">Café de Panamá </a>
        </li>
      </ul>
    </div>

    <div class="col text-left">
      <h3 style="color: white;"  >Comprar</h3>
      <div class="dash"></div>
      <ul>
        <li>
          <a href="https://cafeunido.com/shop/coffee.html" target="_blank">Café</a>
        </li>
        <li>
          <a href="https://cafeunido.com/sp/shop/picante.html" target="_blank">Picante</a>
        </li>
        <li>
          <a href="https://cafeunido.com/sp/shop/equipment.html" target="_blank">Equipo de Café</a>
        </li>
        <li>
          <a href="https://cafeunido.com/sp/shop/merch.html" target="_blank">Merch</a>
        </li>
        <li>
          <a href="https://cafeunido.com/english_usa/" target="_blank"> USA Store </a>
        </li>
       
      </ul>
    </div>

    <div class="col text-left">
      <h3 style="color: white;"  >Contacto</h3>
      <div class="dash"></div>
      <ul>
        <li>
          <a href="https://cafeunido.com/sp/locations/panama/" target="_blank">Ubicaciones</a>
        </li>
        <li>
          <a href="mailto:pedidos@cafeunido.com" target="_blank" >Escribenos</a> 
        </li>
        <li  >
          <a href="https://www.instagram.com/cafeunido/?hl=en" target="_blank" ><img src="../../../assets/images/ig-logo.svg"></a> 
          
        </li>
      </ul>
    </div>
  </div>
</section>
<!-- <footer>
  <p>{{'copy' | transloco}}</p>
</footer> -->


<!-- <footer>
  <div class="column_foot">

  <div class="">
    <h3>Basics</h3>
    <hr>
    <ul>
      <li>  <a href="#">Our Story</a></li>
      <li><a href="#">Our Coffee</a></li>
      <li><a href="#">Visit</a></li>
      <li><a href="#">Events</a></li>
      <li><a href="#">FAQ</a></li>
      <li><a href="#">Blog</a></li>
    </ul>
</div>
  <div class="">
    <h3>Shops</h3>
    <hr>
    <ul>
      <li>  <a href="#">Coffee</a></li>
      <li><a href="#">Brewing</a></li>
      <li><a href="#">Essentials</a></li>
      <li><a href="#">Subscriptions</a></li>
      <li><a href="#">Gift Subscriptions</a></li>
      <li><a href="#">Special Orders</a></li>
      <li><a href="#">Redeem Your Gift</a></li>
      <li><a href="#">Return Policy</a></li>
    </ul>
  </div>
  <div class="">
    <h3>Community</h3>
    <hr>
    <ul>
      <li>  <a href="#">About Us</a></li>
      <li><a href="#">Careers</a></li>
    </ul>
  </div>
  <div class="">
    <h3>Contact Us</h3>
    <hr>
    <ul>
      <li>  <a href="#">0000-0000</a></li>
      <li>  <a href="#">0000-0000</a></li>
      <li><a href="#">Location</a></li>
      <li><a href="#">Email</a></li>
    </ul>
  </div>
    </div>
</footer> -->
