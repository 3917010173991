import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CheckoutComponent} from './pages/checkout/checkout.component';
import {HeaderModule} from './components/header/header.module';
import {HomeComponent} from './pages/home/home.component';
import {PlanModule} from './components/plan/plan.module';
import { HttpClientModule } from '@angular/common/http';
import { TranslocoRootModule } from './transloco/transloco-root.module';
import {FooterModule} from './components/footer/footer.module';
import {ConfirmationComponent} from './pages/confirmation/confirmation.component';
import {WelcomeModule} from './components/welcome/welcome.module';
import { LoginComponent } from './pages/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RegisterComponent } from './pages/register/register.component';
import { TokenInterceptor } from './shared/interceptors/token.interceptor';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ProfileComponent } from './pages/profile/profile.component';
import { RecoverPasswordComponent } from './pages/recover-password/recover-password.component';
import { SuscriptionsComponent } from './pages/suscriptions/suscriptions.component';
import { PaymentsComponent } from './pages/payments/payments.component';
import { AddresesComponent } from './pages/addreses/addreses.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import {MatDialogModule} from '@angular/material/dialog';

import {GoogleAnalyticsService} from './google-analytics.service';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import {  MatTableModule } from '@angular/material/table';

import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { AddressesPopupComponent } from './pages/checkout/addresses-popup/addresses-popup.component';
import {MatRadioModule} from '@angular/material/radio';
import { TdcPopupComponent } from './pages/checkout/tdc-popup/tdc-popup.component';
import { TermsPopupComponent } from './pages/checkout/terms-popup/terms-popup.component';
import { ValidateComponent } from './pages/suscriptions/validate/validate.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { SafeHtmlPipe } from './pages/checkout/safe-html.pipe';
import { MatSelectModule } from "@angular/material/select";
import { NgxSpinnerModule } from "ngx-spinner";
import { FacPopupComponent } from './pages/checkout/fac-popup/fac-popup.component';
import { VerifyTokenComponent } from './pages/verify-token/verify-token.component';
import { UpdatePaymentComponent } from './pages/update-payment/update-payment.component';
import { ToastrModule } from 'ngx-toastr';
import {Ng2TelInputModule} from 'ng2-tel-input';


@NgModule({
  declarations: [
    AppComponent,
    CheckoutComponent,
    ConfirmationComponent,
    HomeComponent,
    LoginComponent,
    RegisterComponent,
    ProfileComponent,
    RecoverPasswordComponent,
    SuscriptionsComponent,
    PaymentsComponent,
    AddresesComponent,
    ChangePasswordComponent,
    AddressesPopupComponent,
    TdcPopupComponent,
    TermsPopupComponent,
    ValidateComponent,
    SafeHtmlPipe,
    FacPopupComponent,
    VerifyTokenComponent,
    UpdatePaymentComponent
  ],
  imports: [
    BrowserModule,
    NgxSpinnerModule,
    MatInputModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatSelectModule,
    MatSortModule,
    MatRadioModule,
    MatIconModule,
    MatMenuModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-center',
      preventDuplicates: true
    }), // ToastrModule added
    MatTableModule,
    BrowserModule,
    FormsModule,
    MatDialogModule,
    ReactiveFormsModule,
    AppRoutingModule,
    FooterModule,
    HeaderModule,
    Ng2TelInputModule,
    PlanModule,
    HttpClientModule,
    TranslocoRootModule,
    WelcomeModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [// REQUIRED IF YOU USE JWT AUTHENTICATION
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },GoogleAnalyticsService],
  bootstrap: [AppComponent]
})
export class AppModule {
}
