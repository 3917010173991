import { Injectable } from "@angular/core";
import { HttpHeaders } from '@angular/common/http';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from "@angular/common/http";
import { Observable } from "rxjs";
import { JwtAuthService } from "../../services/auth/jwt-auth.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private jwtAuth: JwtAuthService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    var token = this.jwtAuth.token || this.jwtAuth.getJwtToken();
    var changedReq;
    var user: any = -1 ;

    if(localStorage.getItem('user') != null){
      user = localStorage.getItem('user');
    }

    if (token) {
      changedReq = req.clone({
        headers: new HttpHeaders({
          'Authorization': token,
          'User': user.toString(),
        })
      });
    
    } else {
     
      changedReq = req;
      
    }
    return next.handle(changedReq);
  }
}
