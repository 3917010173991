import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { JwtAuthService } from '../../services/auth/jwt-auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import {ServiceService} from '../../services/service.service';
import { CustomValidators } from 'ngx-custom-validators';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  form: any;
  error : any;
  success : any = false;

  email : any = true;
  partner : any;
 
 
  constructor(private spinner: NgxSpinnerService,private call : ServiceService,private jwtAuth: JwtAuthService,private router: Router,private activateRoute: ActivatedRoute) {
    
   }

  ngOnInit(): void {
    this.spinner.show();
    const newPassword = new FormControl('', [Validators.required,Validators.minLength(6)]);
    const newPasswordConfirm = new FormControl('', [Validators.required, CustomValidators.equalTo(newPassword)]);

    this.form = new FormGroup({
      actual_password: new FormControl('', [Validators.required]),
      new_password: newPassword,
      newPasswordConfirm: newPasswordConfirm
    });

    this.call.getService(Number(localStorage.getItem("service"))).subscribe(response =>{
      this.spinner.hide();
      this.partner = response.partner_id;
    }, err =>{
      this.spinner.hide();
      console.log(err);
    });

    this.spinner.hide();
  }

  change(){
    if(this.error){
      this.error = ''
    }
    this.spinner.show();
    let data = this.form.value;
    data .partner = Number(this.partner);
    let info : any = localStorage.getItem('currentData');
      this.call.changePassword(data,JSON.parse(info).user.authUser.id).subscribe(response=>{
        this.error = false;
        this.success = true;
        this.ngOnInit();
    }, err =>{
      this.success = false;
      this.error = err;
      console.log(err);
      this.spinner.hide();
    });
  }
}
