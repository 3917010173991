import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CheckoutComponent} from './pages/checkout/checkout.component';
import {HomeComponent} from './pages/home/home.component';
import {ConfirmationComponent} from './pages/confirmation/confirmation.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { RecoverPasswordComponent } from './pages/recover-password/recover-password.component';
import { SuscriptionsComponent } from './pages/suscriptions/suscriptions.component';
import { PaymentsComponent } from './pages/payments/payments.component';
import { AddresesComponent } from './pages/addreses/addreses.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { VerifyTokenComponent } from './pages/verify-token/verify-token.component';
import { UpdatePaymentComponent } from './pages/update-payment/update-payment.component';

const routes: Routes = [
  {
    path: '',
    children:[
      {
        path: '',
        component: HomeComponent,
      },
      {
        path: ':service/checkout',
        component: CheckoutComponent,
      },
      
      {
        component: LoginComponent,
        path: ':service/login'
      },
      {
        component: RecoverPasswordComponent,
        path: ':service/recover'
      },
      {
        component: RegisterComponent,
        path: ':service/register'
      },
      {
        component: ProfileComponent,
        path: ':service/profile'
      },
      {
        component: SuscriptionsComponent,
        path: ':service/suscriptions'
      },
      {
        component: PaymentsComponent,
        path: ':service/payments'
      },
      {
        component: AddresesComponent,
        path: ':service/addreses'
      },
      {
        component: ChangePasswordComponent,
        path: ':service/change-password'
      }
    ]
  },
  {
    component: LoginComponent,
    path: 'login'
  },
  {
    component: ConfirmationComponent,
    path: 'confirmation'
  },
  {
    component: VerifyTokenComponent,
    path: 'verifyToken'
  },
  {
    component: UpdatePaymentComponent,
    path: 'updatePayment/:chp/:token'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
