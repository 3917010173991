import {Component, OnInit,HostListener} from '@angular/core';
import {ServiceService} from '../../services/service.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { JwtAuthService } from '../../services/auth/jwt-auth.service';
import { single } from 'rxjs/operators';
import {FacPopupComponent} from '../fac-popup/fac-popup.component'
import { DomSanitizer} from '@angular/platform-browser';
import { NgxSpinnerService } from "ngx-spinner";
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { BaseService } from '../../services/base.service';

@Component({
  selector: 'app-update-payment',
  templateUrl: './update-payment.component.html',
  styleUrls: ['./update-payment.component.scss']
})
export class UpdatePaymentComponent implements OnInit {

  responseForm : any;
  form: any;
  error = '';
  info = '';
  url ='';
  develop = '';
  email : any = true;
  login : any = false;
  signinData : any = {};
  customer:any = -1;
  username:any;
  addres:any;
  password:any;
  id_adress: any = null;
  id_tdc:any = null;
  id_service:any;
  success : any = false;
  option:any;
  id_hasplan:any;
  id_3dsCreditCard: any;
  customer_email:any;
  provinces: any[]= [] ;
  corregimientos: any[]= [] ;
  status_tdc : any;
  id_tdc_corer : any;

  confirmation : any = false;

  tarjetas: any = [];

  userLogged : any = false;
  userRegister : any = false;

  lastTdc : any;


  title = 'united-coffee-landing';

  token : any;
  

  constructor(private call : ServiceService, 
    private router: Router,
    private baseService : BaseService,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
		private activatedRoute: ActivatedRoute, private jwtAuth: JwtAuthService) {
  }

  @HostListener('window:beforeunload', [ '$event' ])
    beforeUnloadHandler(event:any) {
      this.call.deleteToken(this.baseService.HOST + "/campaingToken/" + localStorage.getItem("service") + "/" + this.token).subscribe(response=>{

      }, err =>{

      })
    }

  ngOnInit(): void {

    this.spinner.show();

    this.form = new FormGroup({
      name_tdc : new FormControl('', Validators.required),
      number_tdc: new FormControl('', Validators.required),
      month: new FormControl('', Validators.required),
      year: new FormControl('', Validators.required),
      ccv: new FormControl('', Validators.required),
      partner : new FormControl('', Validators.required),
      service : new FormControl('', Validators.required),
      plan : new FormControl('', Validators.required),
      frecuency : new FormControl('', Validators.required),
    });

    this.activatedRoute.params.subscribe(response =>{
      this.token = response.token;
      this.call.getHasPlan(response.chp).subscribe( chp =>{
        
        this.form.controls["plan"].setValue(chp.plan.name_plan);
        this.form.controls["frecuency"].setValue(chp.plan.frecuency.name_frecuency);
        if(chp.plan.service.id){
          this.form.controls["service"].setValue(chp.plan.service.name_service);
          this.form.controls["partner"].setValue(chp.plan.service.partner.name_partner);
        }else{
          this.call.getService(chp.plan.service).subscribe(service =>{
            this.form.controls["service"].setValue(service.name);
            this.form.controls["partner"].setValue(service.partner);

          });
        }

        this.spinner.hide();
        
      })
    })


  }

  update(){

    this.spinner.show()

    const data = this.form.value;
    data.service = localStorage.getItem("service");
    data.token = this.token;
    let tdc : any = {};
    tdc.numberCreditCard = data.number_tdc;
    delete data.number_tdc;
    tdc.nameHolderCreditCard = data.name_tdc;
    delete data.name_tdc;
    tdc.expMonthCreditCard = data.month;
    delete data.month;
    tdc.expYearCreditCard = data.year;
    delete data.year;

    tdc.ccv = data.ccv;
    delete data.ccv;
    delete data.partner;
    delete data.frecuency;
    delete data.plan;

    data.creditcard = tdc;
    this.call.updatePayment(data).subscribe(response =>{


      if(response.htmlFormData){
       this.id_3dsCreditCard = response.id;
       this.spinner.hide();
      // this.responseForm = response.htmlFormData;  
       let dialogRef: MatDialogRef<any> = this.dialog.open(FacPopupComponent, {
        width: '50%',
        height:'50%',
        disableClose: true,
        data: { form: this.sanitizer.bypassSecurityTrustHtml(response.htmlFormData), id: response.id }
        })
          dialogRef.afterClosed()
              .subscribe(res => {
                this.myLoadEvent();
        })      
     }


    }, err =>{
      this.spinner.hide();
      this.error = err;
      console.log(err)
    });
  }

  onlyNumberKey(event : any){
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  myLoadEvent(){

    this.call.getCC(this.id_3dsCreditCard).subscribe(cc =>{

      if(cc.verified){

        this.confirmation = true;
        this.spinner.hide()

      }
      
    }, err =>{
      this.error = "Ha sido rechazada la afiliación de esta tarjeta por el banco";
      this.form.controls["number_tdc"].setValue('');
      this.spinner.hide()
      console.log(err);
    })
   }

}
