import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {ServiceService} from '../../../services/service.service';
import { MatTableDataSource } from '@angular/material/table';
import { TableService } from '../../../services/pager';
import { PageEvent } from '@angular/material/paginator';
import { BaseService } from '../../../services/base.service';

@Component({
  selector: 'app-addresses-popup',
  templateUrl: './addresses-popup.component.html',
  styleUrls: ['./addresses-popup.component.scss']
})
export class AddressesPopupComponent implements OnInit {

  addressSelected: any;
  addresses : any = [];
  items : any = [];
  show = true;

  displayedColumns: string[] = ['name' ,'adress','actions'];
  dataSource: any;
  empty = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddressesPopupComponent>,
    private call : ServiceService,public  tableService: TableService<any>,private baseService : BaseService
  ) { }


  ngOnInit(): void {
    this.tableService.pager.pageSize = 10 ; 
    
    this.list();

  }

  
  list(event?: PageEvent){

    let httpParams = this.baseService.buildRequestParams(this.tableService.sort,"m",
    {pageIndex: event ? event.pageIndex : this.tableService.pager.pageIndex , pageSize: event ? event.pageSize : this.tableService.pager.pageSize})

    this.call.getAddresses(this.data.customer, httpParams).subscribe(response =>{

      this.items = [];
      for(let r of response.result){
        if( r.status == 1){ // SOLO SI LA DIRECCION SE ENCUENTRA ACTIVA
          this.items.push(r);
        }
      }

      if(this.items.length == 0){
        this.empty = true;
      }

      this.show = false;

      this.dataSource = new MatTableDataSource<any>(this.items);
      this.tableService.pager = response.pager;
      this.tableService.selection.clear();

    
      
    });

  }

  select(row : any) {
    this.dialogRef.close(row);
  }

}
