import { HttpParams, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { throwError } from 'rxjs';
import { Injectable } from "@angular/core";
@Injectable({
    providedIn: "root",
  })
export class BaseService{

    public HOST: string = environment.backendUrl;

    public createLoginHeader(): HttpHeaders {
        return new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded'
          });
    }

 
    public extractData(res: any) {
		return res.result;
	}

	public handleError (error: HttpErrorResponse | any) {
        console.log("ERROR")
        console.log(error);
		if (error instanceof Error) {
			// A client-side or network error occurred. Handle it accordingly.
			console.log('An error occurred: ', error.message ? error.message : error.toString());
		} else {
			// The backend returned an unsuccessful response code.
			// The response body may contain clues as to what went wrong,
            if(error.error != undefined){
                console.log(`Backend returned code: ${error.status} - ${error.statusText}, body was: ${error.message}`);
                return throwError(error.error.message);
            }else{
                return throwError(error);
            }
            
		}
		console.log('Raw error:', error);
		return throwError(error);
    }

    public extractDataFull(res: Response) {
        return res.json();
    }

    public extractHeaders(res: Response) {
        return res.headers;
    }

    public buildRequestParams(sort?: string, collection?: string, pager?: {pageIndex: number, pageSize: number}){
        
        let params = new HttpParams() ;

        if(sort != undefined){
            params = params.set('sort', sort);
        }
        if(collection != undefined){
            params = params.set('collection', collection);
        }

        if(pager != undefined){
            params = params.set('index', pager.pageIndex.toString());
            params= params.set('size', pager.pageSize.toString());
        }
        
        return params;
    }


    public handleResponse(res: Response) {
        return res.ok;
    }

    

  
}
