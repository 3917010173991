<ngx-spinner></ngx-spinner>
<section  class="middle inset">

  <div class="payment-form text-center y-center">
    <div class="steps container-center text-dark">

      <div class="step">
        <span>ACTUALIZAR MÉTODO DE PAGO</span>
      </div>
  
    </div>

    <form *ngIf='!confirmation' [formGroup]="form" (ngSubmit)="update()">
      

      <div class="form">

        <div class="row">

          <div class="form-control-left">

            <mat-form-field style="width: 90%;">
              <input
              readonly
              style="color: black;"
              matInput
              name="company"
              placeholder="Compañía"
              [formControl]="form.controls['partner']" readonly>
            </mat-form-field>
  
            <mat-form-field style="width: 90%;">
              <input
              readonly
              style="color: black;"
              matInput
              name="service"
              placeholder="Servicio"
              [formControl]="form.controls['service']" readonly>
            </mat-form-field>
  
            <mat-form-field style="width: 90%;">
              <input
              readonly
              style="color: black;"
              matInput
              name="plan"
              placeholder="Plan"
              [formControl]="form.controls['plan']" readonly>
            </mat-form-field>
  
            <mat-form-field style="width: 90%;">
              <input
              readonly
              style="color: black;"
              matInput
              name="campany"
              placeholder="frecuency"
              [formControl]="form.controls['frecuency']" readonly>
            </mat-form-field>
  
            
          </div>
  
      


          <div  class="form-control-right">
  
            <mat-form-field style="width: 90%;">
              <input
              style="color: black;"
              matInput
              name="name"
              placeholder="Nombre del Titular"
              [formControl]="form.controls['name_tdc']" >
            </mat-form-field>
  
            <mat-form-field style="width: 90%;">
              <input
              
              style="color: black;"
              matInput
              name="number"
              placeholder="Número de Tarjeta"
              [formControl]="form.controls['number_tdc']" >
            </mat-form-field>
  <div>
            <mat-form-field style="width: 90%;">
              <mat-label>Mes de Expiración*</mat-label>
              <mat-select  [formControl]="form.controls['month']"  >
                  <mat-option value="01" >ENERO</mat-option>
                  <mat-option value="02" >FEBRERO</mat-option>
                  <mat-option value="03" >MARZO</mat-option>
                  <mat-option value="04" >ABRIL</mat-option>
                  <mat-option value="05" >MAYO</mat-option>
                  <mat-option value="06" >JUNIO</mat-option>
                  <mat-option value="07" >JULIO</mat-option>
                  <mat-option value="08" >AGOSTO</mat-option>
                  <mat-option value="09" >SEPTIEMBRE</mat-option>
                  <mat-option value="10" >OCTUBRE</mat-option>
                  <mat-option value="11" >NOVIEMBRE</mat-option>
                  <mat-option value="12" >DICIEMBRE</mat-option>
              </mat-select>
            </mat-form-field>
  
  
            <mat-form-field style="width: 90%;">
              <mat-label>Año de Expiración*</mat-label>
              <mat-select  [formControl]="form.controls['year']"  >
                  <mat-option value="21">2021</mat-option>
                  <mat-option value="22">2022</mat-option>
                  <mat-option value="23">2023</mat-option>
                  <mat-option value="24">2024</mat-option>
                  <mat-option value="25">2025</mat-option>
                  <mat-option value="26">2026</mat-option>
                  <mat-option value="27">2027</mat-option>
                  <mat-option value="28">2028</mat-option>
                  <mat-option value="29">2029</mat-option>
                  <mat-option value="30">2030</mat-option>
                  <mat-option value="31">2031</mat-option>
                  <mat-option value="32">2032</mat-option>
                  <mat-option value="33">2033</mat-option>
                  <mat-option value="34">2034</mat-option>
                  <mat-option value="35">2035</mat-option>
                  <mat-option value="36">2036</mat-option>
                  <mat-option value="37">2037</mat-option>
                  <mat-option value="38">2038</mat-option>
                  <mat-option value="39">2039</mat-option>
                  <mat-option value="40">2040</mat-option>
              </mat-select>
            </mat-form-field>
</div>  
  
            <mat-form-field style="width: 90%;">
              <input
              
              style="color: black;"
              matInput
              name="ccv"
              placeholder="CCV"
              [formControl]="form.controls['ccv']" >
            </mat-form-field>
  
          
          </div>

        </div>

  
      </div>

      
      <small 
      *ngIf="error" 
      class="form-error-msg"> ERROR !!! {{ error }} </small>


      <div>
        <button class="btn" [disabled]="form.invalid" >Actualizar</button>
        <br/>
      </div>


    </form>

    <div *ngIf='confirmation' >
      <span  class="text-dark">Felicidades, su tarjeta ha sido actualizada para esta suscripción y recibirá un correo con esta confirmación.</span>
      
    </div>

    

    
  </div>

  <!-- style="width:600px;height:600px;border:0;" style="position: absolute;width:0;height:0;border:0;" 

  <iframe (load)="myLoadEvent()" style="width:600px;height:600px;border:0;"   *ngIf="responseForm" [srcdoc]="responseForm | safeHtml"></iframe> -->

 

  
  <div class="ending"></div>
</section>
