import { Component } from '@angular/core';
import {ServiceService} from '../../services/service.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import{GoogleAnalyticsService} from '../../google-analytics.service';
import { environment } from '../../../environments/environment';
import { ToastService } from 'src/app/services/notification/toast.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  service: any;
  selected: any;
  selectedExpresso: any;
  selectedPlan: any;
  title = 'united-coffee-landing';

  plans : Array<any> = [];

  optionsSeleted : Array<any> = [];
  optionsExpressoSeleted : Array<any> = [];

  constructor( 
    private googleAnalyticsService: GoogleAnalyticsService,
    private spinner: NgxSpinnerService,
    private call : ServiceService, 
    private router: Router,
		private activatedRoute: ActivatedRoute,
    private toast : ToastService){
    
  }
  ngOnInit(){

    this.spinner.show();
    if(localStorage.getItem('currentData')!= null){
      this.sendMessage();
    }
    console.log(window.location.host);
    this.call.getConfig(window.location.host).subscribe(response =>{
        this.call.getService(response.value_config).subscribe(service =>{
          this.service = service;
          localStorage.setItem("service",service.id);
          this.call.getPlans(response.value_config).subscribe(plans =>{
            
            this.plans = plans.result;
            this.selectedExpresso = this.plans[0];
            this.optionsExpressoSeleted = this.plans[0].options;
            this.selectedPlan = this.plans[3];
            this.optionsSeleted = this.plans[3].options

            // for(const plan of plans["result"]){
            //   this.call.getOptions(plan.id).subscribe(response =>{
            //    this.plans.push({plan:plan, options: response["result"]})
            //    this.plans.sort((n1,n2) =>  n1.plan.position - n2.plan.position);
            //   });
             
            // }

            this.spinner.hide();
           }, err =>{
            this.spinner.hide();
            console.log(err);
           }); 
        }, err =>{
          this.spinner.hide();
      console.log(err);
        });
     
        
      

    }, err =>{
      this.spinner.hide();
      console.log(err);
    }); 
  }

  sendMessage(): void {
    // send message to subscribers via observable subject
    this.call.sendUpdate('Message from Sender Component to Receiver Component!');
  } 

  capsulePlanSelected(selected:any){
      console.log(selected);
      this.optionsSeleted = selected.options;
  }

  expressoPlanSelected(selected:any){
    console.log(selected);
    this.optionsExpressoSeleted = selected.options;
}

  goPlan(result:any,required:boolean){

    if(required){
    if(this.selected === undefined){
      return this.toast.showError("Debe seleccionar una opcion de grano para continuar con la compra");
    }
    }else{
      this.selected = null;
    }

    localStorage.setItem('plan',result);
    // ENVIAMOS EVENTO DE SELECCION DE PLAN A GA SOLO SI ESTAMOS EN PRODUCCION
    if(environment.production){

      //let data : any = localStorage.getItem('currentData');
      //this.customer = JSON.parse(data).customer
      let customer;

      if(localStorage.getItem('currentData') != null){
        let data : any = localStorage.getItem('currentData');
        customer = JSON.parse(data).customer;
      }else{
        customer = "N/A"
      }

      this
      .googleAnalyticsService
      .eventEmitterSelection("add_to_cart", "ecommerce", "click", "SELECCIONAR", result, this.selected, customer);
    }
    if(required){
      this.router.navigate(["/" + this.service.id + "/checkout"], { queryParams: { option: this.selected } });
    }else{
      this.router.navigate(["/" + this.service.id + "/checkout"]);
    }
  }
  

}
