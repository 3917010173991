<ngx-spinner></ngx-spinner>
<section class="middle inset">
    <div class="payment-form text-center y-center">

      
        <div class="steps container-center text-dark">
            <div class="step">
              <span>Mis Direcciones</span>
            </div>
        </div>


        <form *ngIf="register"  [formGroup]="form" (ngSubmit)="send()">
            <div class="form">

                <div class="row">
                    <div class="form-control">
                      <input  [formControl]="form.controls['name']" type="text" placeholder="Nombre"/>
                      <small
                      *ngIf="form.controls['name'].hasError('required') && form.controls['name'].touched" 
                      class="form-error-msg"> Nombre es requerido </small>
                    </div>
                    <div class="form-control">
                      <input [formControl]="form.controls['lastname']" type="text" placeholder="Apellido"/>
                      <small
                      *ngIf="form.controls['lastname'].hasError('required') && form.controls['lastname'].touched" 
                      class="form-error-msg"> Apellido es requerido </small>
                    </div>
                    
                </div>

                <div class="row">
                 
                
                <div class="form-control">
                  <mat-form-field style="width: 100%;">
                    <mat-label>Provincia</mat-label>
                    <mat-select (selectionChange)="onProvinceChange($event.value)" [formControl]="form.controls['province']"  >
                        <mat-option *ngFor="let p of provinces" [value]="p">{{p.name}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <small
                      *ngIf="form.controls['province'].hasError('required') && form.controls['province'].touched" 
                      class="form-error-msg"> Provincia es requerido </small>
                </div>

                <div class="form-control">
                  <mat-form-field style="width: 100%;">
                    <mat-label>Corregimiento</mat-label>
                    <mat-select  [formControl]="form.controls['corregimiento']"  >
                        <mat-option *ngFor="let c of corregimientos" [value]="{id: c.id}">{{c.name}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <small
                      *ngIf="form.controls['corregimiento'].hasError('required') && form.controls['corregimiento'].touched" 
                      class="form-error-msg"> Corregimiento es requerido </small>
                </div>


                <div class="form-control">

                  <input style="width: 70%; margin-left: 85px; padding-left: 0px;"  (hasError)="hasError($event)"  (countryChange)="onCountryChange($event)" (intlTelInputObject)="telInputObject($event)" (ng2TelOutput)="getNumber($event)" [ng2TelInputOptions]="{initialCountry: 'pa',separateDialCode:true}" type="text" ng2TelInput />


                 <small
                 *ngIf="!errorNumber" 
                 class="form-error-msg"> Número de teléfono inválido </small>

                  <!-- <input [formControl]="form.controls['phone']"  (keypress)="onlyNumberKey($event)"  type="text" placeholder="Teléfono"/>
                  <small
                  *ngIf="form.controls['phone'].hasError('required') && form.controls['phone'].touched" 
                  class="form-error-msg"> Teléfono es requerido </small> -->
                </div>
                
                 
              </div>


                <div class="row">

                    <div class="form-control">
                     

                      <textarea formControlName="adress" placeholder="Direccion">    </textarea>
                      <br>
                      <small 
                      *ngIf="form.controls['adress'].hasError('required') && form.controls['adress'].touched" 
                      class="form-error-msg"> Direccion es requerido </small>
                    </div>

                  </div>

              </div>

              <small 
              *ngIf="error" 
              class="form-error-msg"> ERROR !!! {{ error }} , disculpe por favor verifique datos</small>


              <button style="margin-right: 20px; width: 14%;"  type="button" (click)="goBack()" >Cancelar</button>

              <button class="btn" [disabled]="form.invalid || !numberRequired" >Registrar</button>
          
        </form>

        <form *ngIf="edit"  [formGroup]="formEdit" (ngSubmit)="sendEdit()">
            <div class="form">

                <input [formControl]="formEdit.controls['id']" type="text" style="display: none;"/>

                <div class="row">
                    <div class="form-control">
                      <input  [formControl]="formEdit.controls['name']" type="text" placeholder="Nombre"/>
                      <small
                      *ngIf="formEdit.controls['name'].hasError('required') && formEdit.controls['name'].touched" 
                      class="form-error-msg"> Nombre es requerido </small>
                    </div>
                    <div class="form-control">
                      <input [formControl]="formEdit.controls['lastname']" type="text" placeholder="Apellido"/>
                      <small
                      *ngIf="formEdit.controls['lastname'].hasError('required') && formEdit.controls['lastname'].touched" 
                      class="form-error-msg"> Apellido es requerido </small>
                    </div>
                   
                </div>

                <div class="row">
                 

                  <div class="form-control">
                    <mat-form-field style="width: 100%;">
                      <mat-label>Provincia</mat-label>
                      <mat-select (selectionChange)="onProvinceEditChange($event.value)" [formControl]="formEdit.controls['province']"  >
                          <mat-option *ngFor="let p of provinces" [value]="p.id">{{p.name}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <small
                        *ngIf="formEdit.controls['province'].hasError('required') && formEdit.controls['province'].touched" 
                        class="form-error-msg"> Provincia es requerido </small>
                  </div>
  
                  <div class="form-control">
                    <mat-form-field style="width: 100%;">
                      <mat-label>Corregimientos</mat-label>
                      <mat-select  [formControl]="formEdit.controls['corregimiento']"  >
                          <mat-option *ngFor="let c of corregimientos" [value]="c.id">{{c.name}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <small
                        *ngIf="formEdit.controls['corregimiento'].hasError('required') && formEdit.controls['corregimiento'].touched" 
                        class="form-error-msg"> Corregimiento es requerido </small>
                  </div>

                  <div class="form-control">

                    <input style="width: 70%; margin-left: 85px; padding-left: 0px;" [(ngModel)]="phone" [ngModelOptions]="{standalone: true}" (hasError)="hasError($event)"  (countryChange)="onCountryChange($event)" (intlTelInputObject)="telInputObject($event)" (ng2TelOutput)="getEditNumber($event)" [ng2TelInputOptions]="configuration" type="text" ng2TelInput />


                    <small
                    *ngIf="!errorNumber" 
                    class="form-error-msg"> Número de teléfono inválido </small>


                    <!-- <input [formControl]="formEdit.controls['phone']"  (keypress)="onlyNumberKey($event)"  type="text" placeholder="Teléfono"/>
                    <small
                    *ngIf="formEdit.controls['phone'].hasError('required') && formEdit.controls['phone'].touched" 
                    class="form-error-msg"> Teléfono es requerido </small> -->
                  </div>
                 
              </div>

                

                <div class="row">

                    <div class="form-control">
                     

                      <textarea formControlName="adress" placeholder="Direccion">    </textarea>
                      <br>
                      <small 
                      *ngIf="form.controls['adress'].hasError('required') && formEdit.controls['adress'].touched" 
                      class="form-error-msg"> Direccion es requerido </small>
                    </div>

                  </div>

              </div>

              <small 
              *ngIf="error" 
              class="form-error-msg"> ERROR !!! {{ error }} , disculpe por favor verifique datos</small>


              <button style="margin-right: 20px; width: 14%;"  type="button" (click)="goBackEdit()" >Cancelar</button>

              <button class="btn" [disabled]="formEdit.invalid" >Editar</button>
          
        </form>

        <div *ngIf="!register && !edit">
            <div class="m-333" fxLayout="row" style="text-align: end;">
                <!-- <span fxFlex></span> -->
                <button  mat-raised-button class="mb-8" color="primary" (click)="add()" >Agregar</button>
            </div>
    
    
            <div class="ml-8 mr-8 mt-8 pb-16">
                <div class="mat-elevation-z8">
                    <mat-table [dataSource]="dataSource" matSort >
    
                        <ng-container matColumnDef="adress">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> Dirección </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-label="adress"> {{row.adress}} </mat-cell>
                        </ng-container>
            
                        <ng-container matColumnDef="phone">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> Telefono </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-label="phone"> {{row.phone}} </mat-cell>
                        </ng-container>
            
            
                        <ng-container matColumnDef="status">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> Estatus </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-label="status"> {{row.status}} </mat-cell>
                        </ng-container>


                        <ng-container matColumnDef="actions">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-label="estatus"> 
                                <button style="background-color: white;" mat-icon-button [matMenuTriggerFor]="msgMenu" (click)="$event.stopPropagation()" class="hidden-on-open">
                                    <mat-icon class="text-muted">more_vert</mat-icon>
                                  </button>
                                <mat-menu style="width: 200px;" #msgMenu="matMenu">
                                    <button  mat-menu-item (click)="update(row)"> Actualizar </button>
                                    <button *ngIf="row.status =='Activo'" mat-menu-item (click)="delete(row)"> Eliminar  </button>
                                    <button *ngIf="row.status =='Inactivo'" mat-menu-item (click)="delete(row)"> Activar  </button>
                                </mat-menu>
                            </mat-cell>
                          </ng-container>
            
                    
                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;" >
                        </mat-row>
                    </mat-table>
                    
                    <mat-paginator showFirstLastButtons
                    [length]="tableService.pager.totalEntities"
                    [pageIndex]="tableService.pager.pageIndex"
                        [pageSize]="tableService.pager.pageSize"
                    [pageSizeOptions]="[10, 50, 100]"
                    (page)="list($event)">
                    </mat-paginator>  

                </div>
                
            </div>
        </div>
      
      <div>
      </div>
    </div>

    <small 
              *ngIf="errorUpdate" 
              class="form-error-msg"> ERROR !!! {{ errorUpdate }} </small>
  
    
    <div class="ending"></div>
</section>
