import {Component, Input, OnInit} from '@angular/core';
import {ServiceService} from '../../services/service.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent {

  
  title = 'united-coffee-landing';
  url = '';
  service: any;
  @Input() showBrand = false;
  @Input() showProduct = false;
  @Input() showTitle = false;

  subscriptionName: Subscription = new Subscription; //important to create a subscription

  text = 'Recibirás tu selección acorde a tu plan. En caso de no poder recibirla, podrás congelar tu plan por el tiempo que necesites';
  text2 = 'Gracias por tu afiliación! ';

  constructor( 
    private call : ServiceService, 
    private router: Router,
		private activatedRoute: ActivatedRoute,){

      // subscribe to sender component messages
     this.subscriptionName= this.call.getUpdate2().subscribe
     (message => { //message contains the data sent from service
      //this.service.description = 'Gracias por tu afiliación! ';
      this.text = 'Gracias por tu afiliación! ';
      this.ngOnInit();
     });
    
  }

  ngOnInit(){
    
    let route = this.router.url.split("/");
    
    this.call.getConfig(window.location.host).subscribe(response =>{
        this.call.getService(response.value_config).subscribe(service =>{
        this.service = service;
         //CARGAMOS LA URL DEL LOGO
         this.url = "url('"+service.url_logo+"')";
         if(route.length > 2){
          this.service.description = this.text;
         }
        }); 
    })
    
  }

  getImage(){
    return this.url;
  }
}
