import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import {ServiceService} from '../../services/service.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { findPhoneNumbersInText } from 'libphonenumber-js'


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  signupForm: any;
  phone : any;
  error = null;
  success = false;
  numberRequired = false;
  errorNumber = true;
  from : any = null;

  configuration  = {initialCountry: 'PA',separateDialCode:true} ;

  customer : any;


  constructor(private spinner: NgxSpinnerService,private call : ServiceService, private router: Router,
		private activatedRoute: ActivatedRoute) {
      let data: any = localStorage.getItem('currentData');
      this.configuration  = {initialCountry: JSON.parse(data).country ,separateDialCode:true} ;
    }

  ngOnInit(): void {

    this.spinner.show();
    if(localStorage.getItem('currentData')!= null){

      let data: any = localStorage.getItem('currentData');
      this.customer = JSON.parse(data).customer
   
    }else{
      console.log("NO TIENE LA DATA")
    }

    this.sendMessage();
    this.signupForm = new FormGroup({
      id : new FormControl('',Validators.required),
      status_customer : new FormControl('',Validators.required),
      name_customer: new FormControl('', Validators.required),
      lastName_customer: new FormControl( '' , Validators.required),
      identifier_customer: new FormControl( '', Validators.required),
      phoneNumber_customer:new FormControl(''),
      email_customer: new FormControl('', [Validators.required,Validators.email])
    });

    // LLENAMOS LOS DATOS DEL CLIENTE EN PANTALLA
    this.call.getCustomer(this.customer).subscribe(response =>{

      if(response.phoneNumber_customer != undefined || response.phoneNumber_customer != null){

        let findPhone = findPhoneNumbersInText(response.phoneNumber_customer);
        if(findPhone.length > 0){
          this.phone = findPhone[0].number.nationalNumber;
        }
        
      }

      this.signupForm.controls["id"].setValue(response.id);
      this.signupForm.controls["status_customer"].setValue(response.status_customer);
      this.signupForm.controls["name_customer"].setValue(response.name_customer);
      this.signupForm.controls["lastName_customer"].setValue(response.lastName_customer);
      this.signupForm.controls["identifier_customer"].setValue(response.identifier_customer);
      this.signupForm.controls["email_customer"].setValue(response.email_customer);
      this.spinner.hide();


    }, err =>{
      console.log(err);
      this.spinner.hide();
    });
  }

  
  sendMessage(): void {
    // send message to subscribers via observable subject
    this.call.sendUpdate('Message from Sender Component to Receiver Component!');
  } 

  refreshComponent(){
    this.router.navigate([this.router.url])
 }

  update() {

    this.spinner.show();
    const signinData = this.signupForm.value;
   
    // COMO ES CLIENTE DE LANDING, SU EMAIL SERA EL CODIGO DEL CLIENTE
    signinData.code_customer = signinData.email_customer;

    this.activatedRoute.params.subscribe(params =>{

      this.call.getService(params["service"]).subscribe(service =>{
       
        // ESTE ES EL PARTNER QUE ES DUE*O DEL PLAN Y SERVICIO
        signinData.partner = {id:service.partner_id};
       
        this.call.update(signinData).subscribe(result =>{
          this.success = true;
          this.ngOnInit();

          
        }, err => {
          //this.notificationService.error(err);
          this.spinner.hide();
          console.log(err);
          this.error = err;
        }) 
      });
   
      
    });

  }


  hasError(obj:any){
    // FALSE : ERROR - TRUE -> NO ERROR
    this.errorNumber = obj;
    if(obj){
      this.numberRequired = true;
      console.log(this.numberRequired);
    }
  }

  onCountryChange(obj:any){
    //console.log(obj);
  }

  telInputObject(obj:any){
    //console.log(obj);
  }

  getNumber(obj:any){
    this.signupForm.controls["phoneNumber_customer"].setValue(obj);
  }

}
