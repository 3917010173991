import {NgModule} from '@angular/core';
import {WelcomeComponent} from './welcome.component';
import {RouterModule} from '@angular/router';
import {TranslocoModule} from '@ngneat/transloco';
import {CommonModule} from '@angular/common';

@NgModule({
  declarations: [
    WelcomeComponent
  ],
  providers: [],
  exports: [
    WelcomeComponent
  ],
  imports: [
    RouterModule,
    TranslocoModule,
    CommonModule
  ],
  bootstrap: [WelcomeComponent]
})
export class WelcomeModule {
}
