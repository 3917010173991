import { Component, OnDestroy} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import {ServiceService} from '../../services/service.service';



@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnDestroy{
  title = 'united-coffee-landing';
  service: any;

  sesion : any = false;
  customer: any;

  idservice : any;

  messageReceived: any;
  subscriptionName: Subscription = new Subscription; //important to create a subscription

  isMenuOpen = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private call : ServiceService
   
  ) {
     // subscribe to sender component messages
     this.subscriptionName= this.call.getUpdate().subscribe
     (message => { //message contains the data sent from service
      this.messageReceived = message;
      this.ngOnInit();
     });
  }

  ngOnInit(): void {
    let data : any = localStorage.getItem('currentData');
    this.idservice = localStorage.getItem("service");
    if(data != null){
      this.sesion = true;
    }
   
  }


  ngOnDestroy() { // It's a good practice to unsubscribe to ensure no memory leaks
            this.subscriptionName.unsubscribe();
  }

  login(){ // LLEVA A LA VENTANA DEL LOGIN
    this.router.navigate(['/' + localStorage.getItem("service") + '/login'], {relativeTo: this.activatedRoute});
  }

  onLinkClick() {
    this.isMenuOpen = false;
  }
  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  profile(){ // LLEVA A LA PAGINA  DEL PERFIL
    this.router.navigate(['/' + localStorage.getItem("service") + '/profile'], {relativeTo: this.activatedRoute});
  }

  suscriptions(){ // LLEVA A PAGINA DE SUSCRIPCIONES
    this.call.sendUpdate2('false');
    this.router.navigate(['/' + localStorage.getItem("service") + '/suscriptions'], {relativeTo: this.activatedRoute});
  }

  addreses(){ // LLEVA A PAGINA DE DIRECCIONES
    this.router.navigate(['/' + localStorage.getItem("service") + '/addreses'], {relativeTo: this.activatedRoute});
  }

  change(){ //LLEVA A PAGINA DE CAMBIAR LA CLAVE
    this.router.navigate(['/' + localStorage.getItem("service") + '/change-password'], {relativeTo: this.activatedRoute});
  }

  payments(){ // LLEVA A PAGINA DE METODOS DE PAGO
    this.router.navigate(['/' + localStorage.getItem("service") + '/payments'] , {relativeTo: this.activatedRoute});
  }

  logout(){ // SALE DE LA SESION
    localStorage.removeItem('currentData');
    sessionStorage.removeItem('user');
    localStorage.removeItem("plan")
    this.sesion = false;
    this.router.navigateByUrl('/');
    
  }

  goShop(){
    window.location.replace("http://www.cafeunido.com/");
  }
}
