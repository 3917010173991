<ngx-spinner></ngx-spinner>
<section class="middle inset">
    <div class="payment-form text-center y-center">

        <div class="steps container-center text-dark">

            <div class="step">
              <span>Por favor, introduce tu token</span>
            </div>
          </div>


          <form [formGroup]="form">
            <div class="form">
                <div  class="row">
                    <div class="form-control">
                      <input [formControl]="form.controls['token']" type="text" maxlength="8"  (keypress)="onlyNumberKey($event)" placeholder="Introduce tu token"/>
                      <br>
                      <small
                      *ngIf="form.controls['token'].hasError('required') && form.controls['token'].touched" 
                      class="form-error-msg"> Token es requerido </small>
                     
                    </div>
                  </div>
          
              </div>

              <small 
              *ngIf="error" 
              class="form-error-msg"> ERROR !!! {{ error }} </small>

              <small 
              *ngIf="success" 
              class="form-success-msg"> {{ success }}</small>

              <div>
                <button type="button" (click)="verifyToken()" class="btn yellow" [disabled]="form.controls['token'].hasError('required')" >Continuar</button>
                <br>
                <button *ngIf="resend" class="btn dark btn mini underlined" (click)="newToken()"> Solicitar nuevo token</button>
              </div>

             
              
          </form>
  
      
      <div>
        
        <br/>

      </div>
    </div>
  
    
    <div class="ending"></div>
  </section>