import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {ServiceService} from '../../../services/service.service';

@Component({
  selector: 'app-fac-popup',
  templateUrl: './fac-popup.component.html',
  styleUrls: ['./fac-popup.component.scss']
})
export class FacPopupComponent implements OnInit {

  responseForm : any;

  constructor(
    private call : ServiceService, 
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<FacPopupComponent>,
  ) { }

  ngOnInit(): void {
    this.responseForm = this.data.form
  }

  exit() {
    this.call.getHasPlan(this.data.id).subscribe(chp =>{
      if(chp.status_customerPlan  != 4){
        this.dialogRef.close(chp.status_customerPlan);
      }
      
    })
  }

}
