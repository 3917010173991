import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import {ServiceService} from '../../services/service.service';
import { JwtAuthService } from '../../services/auth/jwt-auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  signupForm: any;
  error = null;
  errorNumber = true;
  numberRequired = false;

  

  constructor(private spinner: NgxSpinnerService,private call : ServiceService, private router: Router,
		private activatedRoute: ActivatedRoute, private jwtAuth: JwtAuthService) { }

  ngOnInit(): void {

    this.signupForm = new FormGroup({
      name_customer: new FormControl('', Validators.required),
      lastName_customer: new FormControl('', Validators.required),
      identifier_customer: new FormControl('', Validators.required),
      email_customer: new FormControl('', [Validators.required,Validators.email]),
      phoneNumber_customer:new FormControl('',Validators.required)

    });

  }

  signup() {

    if(this.error){
      this.error = null;
    }
    this.spinner.show();
    const signinData = this.signupForm.value;
    // COLOCAMOS ESTATUS E ACTIVO AL CLIENTE
    signinData.status_customer = 1;
    // COMO ES CLIENTE DE LANDING, SU EMAIL SERA EL CODIGO DEL CLIENTE
    signinData.code_customer = signinData.email_customer;

    this.activatedRoute.params.subscribe(params =>{

      this.call.getService(params["service"]).subscribe(service =>{
       
        // ESTE ES EL PARTNER QUE ES DUE*O DEL PLAN Y SERVICIO
        signinData.partner = {id:service.partner_id};
        signinData.service = Number(params["service"]);
        this.call.registryCustomer(signinData).subscribe(result =>{
           this.signIn(result.customer.email_customer , result.accessToken, params.service, result.customer.id, service.partner_id)
        }, err => {
          console.log("paso por el error")
          this.spinner.hide();
          //this.notificationService.error(err);
          console.log(err);
          this.error = err;
        })
      }); 
   
      
    });

  }

  signIn(username:any,password:any,service:any, customer:any, partner : any){
    this.jwtAuth.signin(username , password, partner)
    .subscribe(response => {
      
      let data : any = localStorage.getItem('currentData');
      if(data == null){
        this.signIn(username,password,service,customer, partner);
      }else{
        this.spinner.hide();
        this.router.navigateByUrl("/");
      }
      

    }, err => {
    
      console.log(err);
      this.error = err;
      this.spinner.hide();
  
    })
  }

   //Permite solo numeros en el numero de la TDC
   onlyNumberKey(event : any){
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }


  hasError(obj:any){
    // FALSE : ERROR - TRUE -> NO ERROR
    this.errorNumber = obj;
    if(obj){
      this.numberRequired = true;
    }
  }

  onCountryChange(obj:any){
    //console.log(obj);
  }

  telInputObject(obj:any){
    //console.log(obj);
  }

  getNumber(obj:any){
    this.signupForm.controls["phoneNumber_customer"].setValue(obj);
  }


}
