<app-welcome></app-welcome>
<section class="middle inset">
  <app-plan
    [class]="'checkout'"
    [description]="plan.description | transloco"
    [image]="plan.image"
    [title]="plan.title | transloco"
  ></app-plan>

  <div class="confirm-form text-center y-center">
    <div class="steps container-center text-dark">
      <div class="point">
        <div>2</div>
      </div>
      <div class="step">
        <span>CONFIRMACIÓN</span>
      </div>
      <div class="line"></div>
    </div>

    <div class="voucher">
      <div class="text-justify y-center">
        <span class="text-font-light">Felicidades, su pedido ha sido confirmado con <br/>el siguiente número de confirmación </span>
        <span class="text-yellow">#045462.</span>
        <br/>
        <br/>
        <span class="text-font-light">Recibirás un correo a </span><span class="text-yellow">aaa.b@gmail.com</span>
        <span class="text-font-light"> con <br/> tu confirmación.</span>
      </div>
    </div>

    <div>
      <h3 class="text-dark">Gracias por comprar con nosotros.</h3>
    </div>
  </div>

  <img src="assets/images/coffee-plant.svg"/>
</section>
