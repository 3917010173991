<ngx-spinner></ngx-spinner>
<section class="middle inset">

  <div class="payment-form text-center y-center">

        <div class="steps container-center text-dark">
            <div class="step">
              <span>Mis suscripciones</span>
            </div>
        </div>

        <form *ngIf="edit"  [formGroup]="formEdit" (ngSubmit)="sendEdit()">
            <div class="form">

                <input [formControl]="formEdit.controls['id']" type="text" style="display: none;"/>

                <div class="row">
                    <div class="form-control">
                      <label class="text-dark">Producto</label>
                      <input readonly [formControl]="formEdit.controls['product']" type="text" placeholder="Producto"/>
                      
                    </div>
                    <div class="form-control">
                      <label class="text-dark">Precio</label>
                      <input readonly [formControl]="formEdit.controls['price']" type="text" placeholder="Precio"/> 
                     
                    </div>
                </div>

                <div class="row">
                  <div class="form-control">
                    <label class="text-dark">Frecuencia</label>
                    <input readonly  [formControl]="formEdit.controls['frecuency']" type="text" placeholder="Frecuencia"/>
                    
                  </div>
                  <div class="form-control">
                    <label class="text-dark">Proximo cobro</label>
                    <input readonly [formControl]="formEdit.controls['next']" type="text" placeholder="Proximo Cobro"/>
                    
                  </div>
              </div>


              <div class="row">

                <div class="form-control">
                  <label class="text-dark">Dirección de envío</label>
                      <div style="text-align: end;">
                        <a style="display: block;" class="text-dark" type="button" style="cursor: pointer;" (click)="addressesPopUp({})"> Cambiar dirección</a>
                      </div>
                </div>

                
              </div>

              <div class="row">
                <div class="form-control">

                  <label class="text-dark">Provincia</label>
                  <input readonly [formControl]="formEdit.controls['province']" type="text" placeholder="Provincia"/>
                 
                </div>
      
                <div class="form-control">

                  <label class="text-dark">Corregimiento</label>
                  <input readonly [formControl]="formEdit.controls['corregimiento']" type="text" placeholder="Corregimiento"/>

                </div>
      
              </div>

                <div class="row">

                    <div class="form-control">
                      
                      <textarea  readonly formControlName="adress" placeholder="Direccion a la que se envia">    </textarea>
                      <br>
                     
                    </div>

                </div>

                 <!-- NOMBRE TITULAR Y NUMERO DE TARJETA -->

          <div class="row">

                  <div class="form-control">
                    <label class="text-dark">Tarjeta asociada al servicio</label>
                    <div style="text-align: end;">
                      <a style="display: block;" class="text-dark" type="button" style="cursor: pointer;" (click)="tdcPopUp({})"> Cambiar tarjeta</a>
                    </div>
                  </div>

          </div>

  
        <div class="row">

          
          <div  class="form-control">

            <label class="text-dark">Titular</label>
            
            <input  readonly [formControl]="formEdit.controls['name_tdc']" type="text" placeholder="Nombre del Titular*"/>

          </div>
          <div class="form-control">
            <label class="text-dark">Tarjeta</label>
            <input readonly [formControl]="formEdit.controls['number_tdc']"  (keypress)="onlyNumberKey($event)" type="text" placeholder="Número de Tarjeta*"/>

          </div>
        </div>
  
         <!-- MES Y A*O DE EXPIRACION -->
        <div class="row">
          <div class="form-control">

            <label class="text-dark">Mes</label>
            <input readonly [formControl]="formEdit.controls['month']"  type="text" placeholder="Mes"/>


          </div>
          <div class="form-control">

            <label class="text-dark">Año</label>

            <input readonly [formControl]="formEdit.controls['year']"  type="text" placeholder="año"/>

          </div>

        </div>

        
                <div style="text-align: end;">
                  <a style="display: block;" *ngIf="congelada" class="text-dark" type="button" style="cursor: pointer;" (click)="validateActivatePopUp({})"> Activar suscripcion</a>
               </div>
                <div style="text-align: end;">
                    <a style="display: block;" *ngIf="activa" class="text-dark" type="button" style="cursor: pointer;" (click)="validatePausePopUp({})"> Pausar suscripcion</a>
                </div>
                <div style="text-align: end;">
                  <a class="text-dark" type="button" *ngIf="activa || congelada" style="cursor: pointer;" (click)="validateCancelPopUp({})"> Cancelar suscripcion</a>
                </div>

                

              </div>

              <small 
              *ngIf="error" 
              class="form-error-msg"> ERROR !!! {{ error }} , disculpe por favor verifique datos</small>

              <small 
              *ngIf="info" 
              class="form-info-msg"> {{info}}  </small>
        

              <div style="text-align: start;">
                <button style="margin-right: 20px; width: 14%;"  type="button" (click)="goBackEdit()" >Volver</button>
                <button class="btn" [disabled]="formEdit.invalid" >Editar</button>
              </div>
             

             
          
        </form>

        <div *ngIf="!register && !edit">
            <div class="ml-8 mr-8 mt-8 pb-16">
                <div class="mat-elevation-z8">
                    <mat-table [dataSource]="dataSource" matSort >
    
                        <ng-container matColumnDef="plan.title">
                          <mat-header-cell  *matHeaderCellDef mat-sort-header> Producto </mat-header-cell>
                          <mat-cell   *matCellDef="let row" data-label="plan.title"> {{row.plan.title}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="option.name">
                          <mat-header-cell  *matHeaderCellDef mat-sort-header> Option </mat-header-cell>
                          <mat-cell   *matCellDef="let row" data-label="option.name"> {{row?.option?.name || 'N/A'}} </mat-cell>
                        </ng-container>
            
                        <ng-container matColumnDef="plan.feeCost_plan">
                            <mat-header-cell   *matHeaderCellDef mat-sort-header> Precio</mat-header-cell>
                            <mat-cell  *matCellDef="let row" data-label="plan.feeCost_plan"> ${{row.plan.feeCost_plan}} </mat-cell>
                        </ng-container>
            
                        <ng-container matColumnDef="plan.frecuency.name_frecuency">
                            <mat-header-cell  *matHeaderCellDef mat-sort-header> Frecuencia </mat-header-cell>
                            <mat-cell  *matCellDef="let row" data-label="plan.frecuency.name_frecuency"> {{row.plan.frecuency.name_frecuency}} </mat-cell>
                        </ng-container>
            
                        <ng-container matColumnDef="next_bill_date">
                            <mat-header-cell  *matHeaderCellDef mat-sort-header> Proximo cobro </mat-header-cell>
                            <mat-cell  *matCellDef="let row" data-label="next_bill_date"> {{row.next_bill_date}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="status_customerPlan">
                          <mat-header-cell *matHeaderCellDef mat-sort-header> Estatus</mat-header-cell>
                          <mat-cell  *matCellDef="let row" data-label="status_customerPlan"> {{row.status_customerPlan}} </mat-cell>
                      </ng-container>
            
            
    
                        <ng-container matColumnDef="actions">
                            <mat-header-cell  *matHeaderCellDef mat-sort-header> Acciones </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-label="estatus"> 
                                <button style="background-color: white;" mat-icon-button [matMenuTriggerFor]="msgMenu" (click)="$event.stopPropagation()" class="hidden-on-open">
                                    <mat-icon class="text-muted">more_vert</mat-icon>
                                  </button>
                                <mat-menu style="width: 200px;" #msgMenu="matMenu">
                                    <button  mat-menu-item (click)="update(row)"> Actualizar </button>
                                </mat-menu>
                            </mat-cell>
                          </ng-container>
            
                    
                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;" >
                        </mat-row>
                    </mat-table>

                    <mat-paginator showFirstLastButtons
                    [length]="tableService.pager.totalEntities"
                    [pageIndex]="tableService.pager.pageIndex"
                        [pageSize]="tableService.pager.pageSize"
                    [pageSizeOptions]="[10, 50, 100]"
                    (page)="list($event)">
                    </mat-paginator>  

                </div>
                
            </div>
        </div>
      
      <div>
      </div>
    </div>
  
    
    <div class="ending"></div>
  </section>

